<p-table [value]="auditDetailsData()" lazy="true" (onLazyLoad)="loadItems($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>Username</th>
            <th>Created
                <p-columnFilter field="created" type="date" matchMode="range" display="menu"
                    [showMatchModes]="false" [showClearButton]="false" [showApplyButton]="false" [showOperator]="false"
                    [showAddButton]="false" class="ml-auto audit-column-filter">
                    <ng-template pTemplate="header">
                        <div class="px-3 pt-3 pb-0">
                            <span class="font-bold">Date Range</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-calendar #calendar [ngModel]="value" (onSelect)="filter(calendar.value)"
                            styleClass="p-column-filter" placeholder="Start Date and End Date" selectionMode="range"
                            inputId="range" [readonlyInput]="false" dateFormat="dd-mm-yy"></p-calendar>
                    </ng-template>
                </p-columnFilter>
            </th>
            <th>
                Changes
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
        @if (allDataForAudit().length > 0){
            <tr>
                <td>{{ data.user.username }}</td>
                <td>{{ convertISOToReadable(data.created) }}</td>
                <td><i (click)="showDialog(data)" style="cursor: pointer;" class="pi pi-window-maximize"></i></td>
                </tr>
        }
        @if (allDataForAudit().length == 0){
        <div class="asset-not-found-container">
            <h3 class="asset-not-found"><span>&ldquo;</span>No Data<span>&rdquo;</span></h3>
        </div>
        }
    </ng-template>
    </p-table>
    @if (allDataForAudit().length > 0) {
    <p-paginator (onPageChange)="onPageChange($event)" [totalRecords]="totalPages" [rows]="10" />
    }


<p-dialog header="Changes" [modal]="true" [(visible)]="visible" [style]="{ width: '40rem' }" draggable="false">
    <p-table [value]="differObject()">
        <ng-template pTemplate="header">
            <tr>
                <th>Asset Field</th>
                <th>Changed From</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td style="text-transform: capitalize;">{{data?.assetField}}</td>
                <td>{{data?.changed}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>