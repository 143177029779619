import { Injectable } from '@angular/core';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  private autoCompleteService: any;
  private sessionToken: any;

  constructor() {}

  initialize() {
    if (!this.autoCompleteService || !this.sessionToken) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    }
  }

  searchPlaces(query: string): Promise<{ results: any[], status: string }> {
    this.initialize();

    return new Promise((resolve, _) => {
      if (query.trim().length === 0) {
        resolve({ results: [], status: 'ZERO_RESULTS' });
        return;
      }

      this.autoCompleteService.getPlacePredictions({
        input: query,
        sessionToken: this.sessionToken
      }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve({ results, status });
        } else {
          resolve({ results: [], status });
        }
      });
    });
  }

  getPlaceDetails(placeId: string): Promise<{ place: any, status: string }> {
    return new Promise((resolve, _) => {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({
        placeId: placeId,
        fields: ['geometry', 'formatted_address']
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve({ place, status });
        } else {
          resolve({ place: null, status });
        }
      });
    });
  }
}
