import { Component, ElementRef, Output, ViewChild, EventEmitter, AfterViewInit, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@ngrx/store";
import { getGooglePlaceApiKeySelector } from "../../../state/User/user.selector";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { GooglePlacesService } from "../../../services/googl-places.service";

declare var google: any;

@Component({
  selector: "app-auto-complete-address",
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule],
  templateUrl: "./auto-complete-address.component.html",
  styleUrls: ["./auto-complete-address.component.scss"],
})
export class AutoCompleteAddressComponent implements AfterViewInit {

  @Input() showGeoFenceEdit: boolean;

  @ViewChild('resultContainer') resultContainer: ElementRef;
  @Output() emittingLatLng: EventEmitter<any> = new EventEmitter();

  private googlePlaceApiKey: string;
  private timeoutId: NodeJS.Timeout;

  public searchedPlaceTitle: string;
  public openSearchBar: boolean;

  constructor(private store: Store, private googlePlacesService: GooglePlacesService) { }

  ngAfterViewInit() {
    this.store.select(getGooglePlaceApiKeySelector).subscribe(res => this.googlePlaceApiKey = res);

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googlePlaceApiKey}&libraries=places`;
    script.onload = () => {
      this.googlePlacesService.initialize();
    };
    document.body.appendChild(script);
  }

  searchPlaces(query: string) {
    this.googlePlacesService.searchPlaces(query).then(({ results, status }) => {
      this.resultContainer.nativeElement.innerHTML = '';

      if (status === 'OK') {
        results.forEach(result => {
          const resultTag = document.createElement('p');
          resultTag.innerHTML =
            `<div style="display:flex;gap:10px;padding:5px 5px 5px 20px">
              <i class="pi pi-map-marker"></i>
              <p>${result.description}</p>
            </div>`;

          resultTag.style.marginTop = '7px';
          resultTag.addEventListener('click', () => {
            this.getPlaceDetails(result);
            this.searchedPlaceTitle = result.description;
          });

          this.resultContainer.nativeElement.appendChild(resultTag);
        });
      }
    });
  }

  getPlaceDetails(addressResult) {
    this.googlePlacesService.getPlaceDetails(addressResult.place_id).then(({ place, status }) => {
      if (status === 'OK' && place) {
        this.emittingLatLng.emit({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: addressResult?.description || ''
        });
        this.resultContainer.nativeElement.innerHTML = '';
      }
    });
  }

  suggstPlaces(event) {
    this.timeoutId && clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.searchPlaces(event.target.value);
    }, 350);
  };
}
