import { Component, inject, Input, OnChanges, signal } from '@angular/core';
import { AssetService } from '../../../services/asset.service';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'app-audit-details-config',
  standalone: true,
  imports: [CommonModule, TableModule, FormsModule, PaginatorModule, AccordionModule, IconFieldModule, InputTextModule, ButtonModule, DialogModule, DropdownModule, CalendarModule],
  templateUrl: './audit-details-config.component.html',
  styleUrl: './audit-details-config.component.scss'
})
export class AuditDetailsConfigComponent implements OnChanges {

  private assetService = inject(AssetService)
  auditDetailsData = signal([]);
  differObject = signal({})
  @Input() assetData: IAssetDataForAduit
  @Input() isAuditDetailsTabOpen: boolean;
  visible = signal<boolean>(false)
  allDataForAudit = signal([]);
  totalPages = 1;
  ngOnChanges(changes) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === "isAuditDetailsTabOpen" || propName ==='assetData') {
        if (change.currentValue !== change.previousValue) {
          this.isAuditDetailsTabOpen && this.getAuditDetailsData(this.assetData)
        }
      }
    }
  }



  getAuditDetailsData(assetData: IAssetDataForAduit, startDate?, endDate?) {
    this.assetService.getAuditDetailsData(assetData?._id, startDate, endDate).subscribe((res: IAuditRoot) => {
      this.totalPages = res['pagination']['count']
      this.allDataForAudit.set(res['data']);
      this.auditDetailsData.set(this.paginateData(this.allDataForAudit(), 0, 10))
    })
  }


  convertISOToReadable(dateString:string) {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    return date.toLocaleString('en-US', options);
  }

  showDialog(data:Daum) {
    this.visible.set(true)
    this.differObject.set(this.convertObjectToArray(data['diffObj']))
  }

  convertObjectToArray(obj:DiffObj) {
    return Object.entries(obj).map(([key, value]) => ({
      assetField: key,
      changed: value
    }));
  }


  loadItems(event) {
    let startDate = null;
    let endDate = null
    let filteredValue = event?.filters?.created?.[0]?.value
    startDate = this.dateToMilliseconds(filteredValue?.[0]);
    endDate = filteredValue?.[1] && this.dateToMilliseconds(filteredValue?.[1], true)
    if (startDate && endDate) {
      this.getAuditDetailsData(this.assetData, startDate, endDate)
    }
  }

  dateToMilliseconds(dateString: string,isEnd?:boolean) {
    const date = new Date(dateString);
    if(isEnd) {
      date.setHours(23, 59, 59, 999);
  } else {
      date.setHours(0, 0, 0, 0);
  }
    return date.getTime();
  }

  onPageChange(event) {
    const paginatedData = this.paginateData(this.allDataForAudit(), event.page, event.rows);
    this.auditDetailsData.set(paginatedData)
  }
  paginateData(data, currentPage: number, rowsPerPage: number) {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    return data?.slice(start, end);
  }
}

export interface IAssetDataForAduit {
  _id: string
  assetType: string
}

export interface IAuditRoot {
  data: Daum[]
  pagination: Pagination
}

export interface Daum {
  _id: string
  user: User
  diffObj: DiffObj
  created: string
  accountId: string
  action: string
}

export interface User {
  username: string
  email: string
  firstName: string
  lastName: string
}

export interface DiffObj {
  [key: string]: unknown
}

export interface Pagination {
  count: number
  skip: any
  limit: any
}

export interface IAssetFilterDate {
  startDate: Date,
  endDate: Date
}