import {createAction, props} from '@ngrx/store';
import {IMapUserOptions} from './interfaces/IUserMap';

export const getUserMap = createAction(
  '[ USER map API] gets user map',
);

export const getUserMapSuccess = createAction(
  '[USER] get map from api success',
  props<{ userMap: IMapUserOptions }>()
);

export const failure = createAction(
  '[USER Store] failed',
  props<{ error: Error }>()
);

export const success = createAction(
  '[USER Store] success',
  props<{ message: string }>()
);


export const getAddressDetails = createAction(
  '[ reverse GeoCode API] get address details',
  props<{lat:number,lng:number,fieldKey?:string,avoidAddressUpdate?:boolean} >()
);

export const getAddressDetailsSuccess = createAction(
  '[ reverse GeoCode API] get address details success',
  props<{addressDetails: {
      state: string,
      pincode: unknown,
      city: string,
      address:string
    } }>()
);

export const registerFirebaseToken = createAction(
  '[USER Store] register Firebase Token',
);

export const deleteFirebaseToken = createAction(
  '[USER Store] delete Firebase Token',
);